@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color:   #A084DC; /*edf2f8*/
  --secondary-color: #810CA8; /*#313bac*/
  --black-color: #2D033B; /*#030303*/
  --lightGray-color: #635985; /*#e4e4e4*/
  --gray-color: #393053; /*#6b7688*/
  --brown-color: #3C2A21; /*#46364a*/
  --white-color: #E5B8F4; /*#ffffff*/
  --lightblack-color: #3C1166;
  --neon-color: #9af705;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}